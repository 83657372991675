import { Component, Mixins, Prop } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIFormRow from "@/components/UIFormRow.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import UIModalSpecialDay from "@/components/UlModalSpecialDay.vue";
import UlFileRegister from "@/components/UlFileRegister.vue";
import {
  UIModalSpecialDayModalType,
  UlFileRegisiterTabType
} from "@/utils/enums";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { TableOptions } from "@/api/request";
import {
  DetailRequest,
  RegisterFuyo,
  RegisterRank,
  RegisterRequest,
  RegisterSpecial,
  RegisterTicket
} from "@/api/point-setting/request";
import { GetRequest as GetSpecialRequest } from "@/api/point-special/request";
import { GetRequest as GetFuyoRequest } from "@/api/point-fuyo/request";
import { GetRequest as GetRankRequest } from "@/api/point-rank/request";
import { DetailSpecial } from "@/api/point-setting/response";
import {
  GetResult as GetSpecialResult,
  GetSpecialDay
} from "@/api/point-special/response";
import { GetResult as GetFuyoResult } from "@/api/point-fuyo/response";
import {
  GetFuyo,
  GetResult as GetRankResult,
  GetTicket
} from "@/api/point-rank/response";
import { GetRequest as GetPreviewRequest } from "@/api/point-ticket-preview/request";
import PointSettingDetail from "@/store/point-setting/detail";
import PointSettingRegister from "@/store/point-setting/register";
import PointSpecialGet from "@/store/point-special/get";
import PointFuyoGet from "@/store/point-fuyo/get";
import PointRankGet from "@/store/point-rank/get";
import PointTicketPreviewGet from "@/store/point-ticket-preview/get";
import Loading from "@/store/loading";
import { SpecialDayModalResponse } from "@/utils/interfaces";
import Flash, { ErrorAlert } from "@/store/common/flash";
import _ from "lodash";
import WindowOpen from "@/utils/window-open";
import { BusinessServiceStorage } from "@/store/business-service-storage";

interface SpecialDay extends GetSpecialResult, DetailSpecial {
  isEdited: boolean;
}

interface Fuyo extends GetFuyoResult {
  isEdited: boolean;
  writeFlg: number;
}

interface Rank extends GetRankResult {
  rankIconEditFlg: 0 | 1;
  rankIconId: number | null;
  isEdited: boolean;
}

const enum NextLabelEnum {
  NEXT = "次へ",
  SAVE = "保存する"
}

const enum PointUseType {
  POINT_SUBTRACTION = 1,
  COMPLETE_TICKET = 2
}

const enum RankRewardType {
  TICKET = 1,
  POINT
}

const enum AddPointType {
  NONE = 1,
  RATE,
  VALUE
}

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    UIFormRow,
    UIDataTable,
    UIDatePicker,
    UIModalSpecialDay,
    UlFileRegister,
    quillEditor
  }
})
export default class Setting extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: true })
  shopId!: string;

  // タイトル
  headingMain = "ポイント";
  headingSub = "Points";

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  editorOption = {
    modules: {
      toolbar: [
        [{ color: [] }, { background: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ size: ["small", false, "large", "huge"] }],
        [{ align: [] }],
        ["link", "image"],
        ["clean"]
      ]
    }
  };

  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "店舗名", value: "shopName", sortable: false },
    { text: "", value: "space", sortable: false },
    { text: "スタンプ画像", value: "image", sortable: false },
    { text: "認証コード", value: "authCode", sortable: false }
  ];

  pointAddTableHeaders = [
    { text: "店舗名", value: "shopName", sortable: false },
    { text: "付与率", value: "custom", sortable: false },
    {
      label: "編集",
      text: "",
      value: "edit",
      sortable: false
    }
  ];

  modalTableHeaders = [
    { text: "店舗名", value: "shopName", sortable: false },
    { text: "付与率", value: "rate", sortable: false }
  ];

  allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  tabType = UlFileRegisiterTabType.Photo;

  addPointItems = [
    { text: "設定しない", value: AddPointType.NONE },
    { text: "割合で設定", value: AddPointType.RATE },
    { text: "金額で設定", value: AddPointType.VALUE }
  ];

  initImageUrl = null as string | null;
  initTicketImageUrl = null as string | null;

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = {} as TableOptions;

  // 1ページ目の表示かどうか
  isFirstPage = true;

  // 次へ or 保存のラベル
  nextLabel = NextLabelEnum.NEXT;

  // ポイントカードID
  pointId = 0;

  // OEM事業者ID
  businessId = 0 as number;

  inputParams = {
    /** 店舗ID */
    shopId: 0,
    /** ポイントカード機能利用 0:使用しない、1:利用する */
    isUse: 0,
    /** ポイントカード運用方法 0:UPLINKポイントカード、1:店舗カード */
    orgPointFlg: null,
    /** ログインURL表示設定 orgPointFlg = 1 の時設定 0:表示しない、1:表示する */
    orgPointUrlFlg: null,
    /** 遷移先URL orgPointFlg = 1 の時設定 */
    orgPointUrl: null,
    /** 遷移ボタン名 orgPointFlg = 1 の時設定 */
    orgPointUrlName: null,
    /** カード説明文 orgPointFlg = 1 の時設定 */
    orgPointDesc: null,
    /** ポイント使用設定 1:店頭のみ、2:店頭＋デジタルチケット、3:デジタルチケットのみ */
    useType: null,
    /** ポイント有効期限 */
    expirationDate: null,
    /** スペシャルデー設定 */
    specialBulkFlg: null,
    /** スペシャル設定配列 */
    special: null,
    /** お友達紹介特典機能設定 0:利用しない、1:利用する */
    introduceFlg: null,
    /** お友達紹介ページ画像編集フラグ */
    introduceImageEditFlg: 0,
    /** お友達紹介ページ画像 */
    introduceImage: null,
    /** t_shop_image_histories.id */
    introduceImageId: null,
    /** お友達紹介ページ紹介文 */
    introduceDescription: null,
    /** 紹介特典ポイント設定（初回DL時） 0:利用しない、1:利用する */
    friendDl: null,
    /** 初回DL時特典ポイント */
    friendIntroduceCount: null,
    /** 紹介者への特典設定（初回DL時） 0:利用しない、1:利用する */
    friendUse: null,
    /** 利用時特典ポイント */
    friendIntroduceCountBoth: null,
    /** 紹介者への特典設定（初回DL時） 0:利用しない、1:利用する */
    userDl: null,
    /** 初回DL時特典ポイント */
    userIntroduceCount: null,
    /** 紹介者への特典設定（利用時） 0:利用しない、1:利用する */
    userUse: null,
    /** 利用時特典設定（利用時） */
    userIntroduceCountBoth: null,
    /** 有効期限開始日 */
    introduceExpireStartDate: null,
    /** 有効期限終了日 */
    introduceExpireEndDate: null,
    /** 紹介リンク有効期限 */
    introduceExpireDayTerm: null,
    /** ポイント利用設定 1:ポイント減算方式、2:コンプリートチケット方式 */
    pointType: null,
    /** ポイントについて */
    description: null,
    /** 初回DL特典 */
    startPoint: null,
    /** 上限ポイント */
    grantPoint: null,
    /** ランキング機能利用フラグ 0:利用しない、1:利用する */
    rankUse: null,
    /** ランク情報配列 */
    rank: [],
    /** ランクについて説明文 */
    rankDescription: null,
    /** ランク特典設定 1:チケット、2:ポイント */
    amenityType: null,
    /** コンプリートチケット有効期限 */
    completeExpirationDate: null,
    /** コンプリートチケット情報 */
    completeTicket: {} as RegisterTicket,
    /** 付与ポイント設定 */
    commonFlg: null,
    /** 付与設定配列 */
    fuyo: [],
    /** プレビューフラグ */
    isPreview: 0
  } as RegisterRequest;

  inputSpecialParams = [] as SpecialDay[];

  inputCommonFuyoParams = [] as Fuyo[];
  inputBranchFuyoParams = [] as Fuyo[];

  inputRankParams = [] as Rank[];

  //NEW_DEV-1545 cyber start
  previewRank = [];
  //NEW_DEV-1545 cyber end

  inputCompTicketParams = {} as RegisterTicket;

  // スペシャルデーモーダル
  showModal = false;
  modalType = UIModalSpecialDayModalType.All;
  selectingSpecial = null as SpecialDay | null;
  specialDayModalResponse = null as SpecialDayModalResponse | null;

  // ランク設定モーダル
  showRankModal = false;
  rankModalTitle = "";
  initRankImageUrl = null as string | null;
  initRankTicketImageUrl = null as string | null;
  rankName = null as string | null;
  rankIconEditFlg = 0 as 0 | 1;
  rankIcon = null as string | null;
  rankIconId = null as number | null;
  targetValue = null as number | null;
  rankFuyo = [] as GetFuyo[];
  rankTicket = {} as GetTicket;
  selectingRank = null as Rank | null;

  // 付与ポイントモーダル
  showAddPointModal = false;
  addPointType = 0;
  addPointPercent = null as number | null;
  amountPerPoint = null as number | null;
  pointsPerAmount = null as number | null;
  selectingFuyo = null as Fuyo | null;

  // パンくず
  get breadCrumbs() {
    return [
      { text: "アピール", disabled: true },
      { text: "ポイント", disabled: true },
      {
        text: "店舗一覧",
        disabled: false,
        to: { name: "points" }
      },
      {
        text: "ポイントカード一覧",
        disabled: false,
        to: {
          name: "points-list",
          params: { shopId: this.shopId },
          query: { shopName: this.shopName }
        }
      },
      { text: "設定", disabled: true }
    ];
  }

  /**
   * クエリパラメータから店舗名を返却する（必須）
   */
  get shopName() {
    if (this.$route.query.shopName) {
      return this.$route.query.shopName;
    } else {
      return "";
    }
  }

  get loading() {
    return Loading.isLoading;
  }

  get showUplinkPointCardSetting() {
    return this.inputParams.isUse === 1 && this.inputParams.orgPointFlg === 0;
  }

  get showShopCardSetting() {
    return this.inputParams.isUse === 1 && this.inputParams.orgPointFlg === 1;
  }

  get showFriendSetting() {
    return (
      this.showUplinkPointCardSetting && this.inputParams.introduceFlg === 1
    );
  }

  get showShopCardDetailSetting() {
    return this.showShopCardSetting && this.inputParams.orgPointFlg === 1;
  }

  get showTicketSetting() {
    /*
    return (
      this.isCompleteTicketType &&
      (this.inputParams.rankUse === 0 || this.isPointRewardType)
    );
    */

    return this.isCompleteTicketType;
  }

  get showAddPointSettingTab() {
    if (this.isPointSubtractionType) {
      // ポイント減算方式&&ランク利用の時
      return false;
    }
    if (
      this.isCompleteTicketType &&
      this.inputParams.rankUse === 1 &&
      this.inputParams.amenityType === RankRewardType.POINT
    ) {
      // コンプリートチケット方式&&ランク利用&&ポイント利用の場合は非表示
      return false;
    }

    return true;
  }

  get showAddPointSetting() {
    if (this.inputParams.rankUse !== 1) {
      return true;
    }
    return this.isCompleteTicketType && this.isTicketRewardType;
  }

  get isPointSubtractionType() {
    return (
      this.inputParams.rankUse === 1 &&
      (this.inputParams.pointType === PointUseType.POINT_SUBTRACTION ||
        this.inputParams.amenityType === RankRewardType.POINT)
    );
  }

  get isCompleteTicketType() {
    // コンプリートチケット方式の場合
    return this.inputParams.pointType === PointUseType.COMPLETE_TICKET;
  }

  get isTicketRewardType() {
    return (
      this.inputParams.rankUse === 1 &&
      this.inputParams.amenityType === RankRewardType.TICKET
    );
  }

  get isPointRewardType() {
    return (
      this.inputParams.rankUse === 1 &&
      this.inputParams.amenityType === RankRewardType.POINT
    );
  }

  get activeUseTypeTab() {
    if (this.inputParams.specialBulkFlg === 0) {
      return 0;
    } else if (this.inputParams.specialBulkFlg === 1) {
      return 1;
    } else {
      return 9;
    }
  }

  set activeUseTypeTab(val) {
    if (val == 0) {
      this.inputParams.specialBulkFlg = 0;
    } else if (val == 1) {
      this.inputParams.specialBulkFlg = 1;
    } else if (val == 9) {
      this.inputParams.specialBulkFlg = 9;
    }
  }

  get activeAddPointTab() {
    return this.inputParams.commonFlg === 1 ? 1 : 0;
  }

  set activeAddPointTab(val) {
    this.inputParams.commonFlg = val === 1 ? 1 : 0;
  }

  get mainSpecialItem() {
    const special = this.inputSpecialParams.find(i => i.isMain === 1 && i.bulkFlg === 0);
    return special ? special : ({} as SpecialDay);
  }

  get branchSpecialItems() {
    const specials = this.inputSpecialParams;
    //店舗別設定のみを取得
    const result = specials.filter((u) => u.bulkFlg === 1);

    return result ? result : ([] as SpecialDay[]);
  }

  get buttonLabel() {
    if (this.showSaveLabel()) {
      return NextLabelEnum.SAVE;
    }
    return this.nextLabel;
  }

  get isSpecialEdit() {
    return this.inputSpecialParams.find(i => i.isMain === 1 && i.bulkFlg === 0 && i.editFlg === 1) ? 1 : 0;
  }

  async created() {
    await PointSettingDetail.detail({
      shopId: Number(this.shopId)
    } as DetailRequest);
    if (PointSettingDetail.isSuccess) {
      this.syncInputParams();
    } else {
      await Flash.setErrorNow({
        message: PointSettingDetail.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
  }

  async beforeDestroy() {
    await PointSettingDetail.clearResponse();
    await PointSettingRegister.clearResponse();
    await PointSpecialGet.clearResponse();
    await PointFuyoGet.clearResponse();
    await PointRankGet.clearResponse();
    await PointTicketPreviewGet.clearResponse();
  }

  async mounted() {
    const storage = BusinessServiceStorage.getLocalStorage();
    this.businessId = storage.id ? storage.id : 0;
  }

  monthlyDateSetting(specialDays: GetSpecialDay | null) {
    //NEW_DEV-1547 cyber start
    if (!specialDays) {
      return "";
    }
    let str = "";
    if (specialDays.monthStart === 1) {
      str = "毎月 月初";
    }
    if (specialDays.monthEnd === 1) {
      if (str.length > 0) {
        str += "、";
      }
      str += "毎月 月末";
    }
    if (specialDays.specific && specialDays.specific.length > 0) {
      if (str.length > 0) {
        str += "、";
      }
      str += specialDays.specific
        .map(s => {
          return `毎月 ${s}日`;
        })
        .join("、");
    }
    return str;
    //NEW_DEV-1547 cyber end
  }

  joinWeekNames(week: number[][] | null) {
    if (!week || !week[0]) {
      return "";
    }
    const w = [
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日",
      "日曜日"
    ];
    let s = [] as string[];
    for (let i = 0; i < week[0].length; i++) {
      if (week[0][i] === 1) {
        s.push(w[i]);
      }
    }
    return s.join("、");
  }

  joinMonthlyWeekNames(week: number[] | null) {
    if (!week || week.length <= 0) {
      return "";
    }
    const w = [
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日",
      "日曜日"
    ];
    let s = [] as string[];
    for (let i = 0; i < week.length; i++) {
      if (week[i] === 1) {
        s.push(w[i]);
      }
    }
    return s.join("、");
  }

  showWeek(week: number[] | null) {
    if (!week) {
      return false;
    }
    let weekCount = 0;
    for (const w of week) {
      weekCount += w;
    }
    return weekCount > 0;
  }

  getRankName(num: number) {
    if (num === 11 || num === 12 || num === 13) {
      return `${num}thランク`;
    }
    if (num % 10 === 1) {
      return `${num}stランク`;
    }
    if (num % 10 === 2) {
      return `${num}ndランク`;
    }
    if (num % 10 === 3) {
      return `${num}rdランク`;
    }
    return `${num}thランク`;
  }

  setFile(data: string, id: number | null) {
    this.inputParams.introduceImageEditFlg = 1;
    this.inputParams.introduceImage = data;
    this.inputParams.introduceImageId = id;
  }

  setTicketFile(data: string, id: number | null) {
    this.inputCompTicketParams.imageEditFlg = 1;
    this.inputCompTicketParams.image = data;
    this.inputCompTicketParams.imageId = id;
  }

  setRankFile(data: string, id: number | null) {
    this.rankIconEditFlg = 1;
    this.rankIcon = data;
    this.rankIconId = id;
  }

  setRankTicketFile(data: string, id: number | null) {
    this.rankTicket.imageEditFlg = 1;
    this.rankTicket.image = data;
    this.rankTicket.imageId = id;
  }

  unsetFile() {
    this.inputParams.introduceImageEditFlg = 1;
    this.inputParams.introduceImage = null;
    this.inputParams.introduceImageId = null;
  }

  unsetTicketFile() {
    this.inputCompTicketParams.imageEditFlg = 1;
    this.inputCompTicketParams.image = null;
    this.inputCompTicketParams.imageId = null;
  }

  unsetRankFile() {
    this.rankIconEditFlg = 1;
    this.rankIcon = null;
    this.rankIconId = null;
  }

  unsetRankTicketFile() {
    this.rankTicket.imageEditFlg = 1;
    this.rankTicket.image = null;
    this.rankTicket.imageId = null;
  }

  goBack() {
    if (this.isFirstPage) {
      this.$router.back();
    } else {
      window.scrollTo(0, 0);
      this.nextLabel = NextLabelEnum.NEXT;
      this.isFirstPage = true;
    }
  }

  async ticketPreview() {
    //NEW_DEV-1706 cyber start
    if (!this.rankTicket.title) {
      await Flash.setErrorNow({
        message: "チケットタイトルは必須です。",
        showReloadButton: false
      } as ErrorAlert);
      return;
    }
    //NEW_DEV-1706 cyber end
    await PointTicketPreviewGet.get({
      shopId: Number(this.shopId),
      type: 2,
      title: this.rankTicket.title,
      imageEditFlg: this.rankTicket.imageEditFlg,
      image: this.rankTicket.image,
      imageId: this.rankTicket.imageId,
      description: this.rankTicket.description,
      attention: this.rankTicket.attention,
      expiration_date: this.inputParams.completeExpirationDate,
      grant_point: this.inputParams.grantPoint
    } as GetPreviewRequest);
    if (PointTicketPreviewGet.isSuccess) {
      WindowOpen.preview(PointTicketPreviewGet.getPreviewUrl);
    } else {
      await Flash.setErrorNow({
        message: PointTicketPreviewGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }

  async compTicketPreview() {
    await PointTicketPreviewGet.get({
      shopId: Number(this.shopId),
      type: 1,
      title: this.inputCompTicketParams.title,
      imageEditFlg: this.inputCompTicketParams.imageEditFlg,
      image: this.inputCompTicketParams.image,
      imageId: this.inputCompTicketParams.imageId,
      description: this.inputCompTicketParams.description,
      attention: this.inputCompTicketParams.attention,
      expiration_date: this.inputParams.completeExpirationDate,
      grant_point: this.inputParams.grantPoint
    } as GetPreviewRequest);
    if (PointTicketPreviewGet.isSuccess) {
      WindowOpen.preview(PointTicketPreviewGet.getPreviewUrl);
    } else {
      await Flash.setErrorNow({
        message: PointTicketPreviewGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }

  async preview() {
    this.inputParams.isPreview = 1;
    //NEW_DEV-1545 cyber start
    var previewIconFlg = 0;
    if (this.inputRankParams[0]) {
      previewIconFlg = this.inputRankParams[0].rankIconEditFlg ? this.inputRankParams[0].rankIconEditFlg : 0;
      if (previewIconFlg == 1) {
        if (this.inputRankParams[0].rankIcon) {
          this.inputRankParams[0].rankIcon = this.inputRankParams[0].rankIcon;
        } else {
          this.inputRankParams[0].rankIcon = null;
        }
        if (this.inputRankParams[0].rankIconId) {
          this.inputRankParams[0].rankIconId = this.inputRankParams[0].rankIconId;
        } else {
          this.inputRankParams[0].rankIconId = null;
        }
      } else {
        if (this.inputRankParams[0]) {
          this.inputRankParams[0].rankIconEditFlg = 1;
        }
        if (this.previewRank[0] && this.previewRank[0].rankIcon) {
          this.inputRankParams[0].rankIcon = this.previewRank[0].rankIcon;
        } else {
          this.inputRankParams[0].rankIcon = null;
        }
      }
    }
    await this.callRegister();
    if (previewIconFlg == 0 && this.inputRankParams[0]) {
      this.inputRankParams[0].rankIconEditFlg = 0;
    }
    //NEW_DEV-1545 cyber end
    if (PointSettingRegister.isSuccess) {
      WindowOpen.preview(PointSettingRegister.getPreviewUrl);
    }
  }

  async goForward() {
    //NEW_DEV-1545 cyber start
    this.inputParams.isPreview = 0;
    //NEW_DEV-1545 cyber end
    if (this.showSaveLabel()) {
      await this.callRegister();
      if (PointSettingRegister.isSuccess) {
        //NEW_DEV-1547 cyber start
        await this.redirectWithSuccessAlert(
          "ポイントカード設定を保存しました。",
          `/points/${this.shopId}/list?shopName=${this.shopName}`
        );
        //NEW_DEV-1547 cyber end
      }
    } else {
      window.scrollTo(0, 0);
      this.nextLabel = NextLabelEnum.SAVE;
      this.isFirstPage = false;
    }
  }

  async callRegister() {
    await PointSettingRegister.register(this.createRegisterRequest());
    if (!PointSettingRegister.isSuccess) {
      await Flash.setErrorNow({
        message: PointSettingRegister.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }
  
  changeFromDateCallback(date: string) {
    this.inputParams.introduceExpireStartDate = date;
  }

  changeToDateCallback(date: string) {
    this.inputParams.introduceExpireEndDate = date;
  }

  addFirstRankRewardCallback() {
    if (this.inputRankParams && !this.inputRankParams.length) {
      const rank = this.inputRankParams.length + 1;
      this.inputRankParams.push({
        id: null,
        rank: rank,
        rankName: this.getRankName(rank)
      } as Rank);
    }
  }

  addRankUpRewardCallback() {
    if (this.inputRankParams && this.inputRankParams.length < 99) {
      const rank = this.inputRankParams.length + 1;
      this.inputRankParams.push({
        id: null,
        rank: rank,
        rankName: this.getRankName(rank)
      } as Rank);
    }
  }

  removeRankUpRewardCallback() {
    if (this.inputRankParams && this.inputRankParams.length > 0) {
      this.inputRankParams.pop();
    }
  }

  async editCommonClickCallback(item: Fuyo) {
    await this.initPointFuyoModal(item, 0);
  }

  async editBranchClickCallback(item: Fuyo) {
    await this.initPointFuyoModal(item, 1);
  }

  async openModalCallback(item: SpecialDay) {
    this.modalType = UIModalSpecialDayModalType.Point;
    this.showModal = true;
    this.selectingSpecial = item;
    if (!item.isEdited && item.id) {
      // APIで値を取得していなければ、取得する
      await this.fetchSpecialDay(item);
      if (PointSpecialGet.isSuccess) {
        item = { ...item, ...PointSpecialGet.getResults };
      } else {
        await Flash.setErrorNow({
          message: PointSpecialGet.getMessage,
          showReloadButton: false
        } as ErrorAlert);
        return;
      }
    }
    // モーダルの data に値を反映
    this.specialDayModalResponse = {
      imagePath: null,
      imageId: null,
      imageEditFlg: 0,
      stampCount: null,
      specialPointType: item.specialPointType ? item.specialPointType : 1,
      specialPointCount: item.specialPointCount,
      specialPointRate: item.specialPointRate,
      dayType: item.dayType ? item.dayType : 1,
      specialDays: _.cloneDeep(item.specialDays),
      weekStart: item.weekStart,
      weekEnd: item.weekEnd
    };
  }

  async openRankSettingCallback(item: Rank) {
    this.showRankModal = true;
    this.selectingRank = item;
    let amenityType = this.inputParams.amenityType;
    // ポイント減算方式でランクアップ機能利用している場合は2を設定
    if (this.inputParams.pointType === 1 && this.inputParams.rankUse === 1)
      amenityType = 2;

    if (!item.isEdited) {
      // APIで値を取得していなければ、取得する
      const request = {
        shopId: Number(this.shopId),
        pointId: this.pointId,
        rank: item.rank,
        amenityType: amenityType
      } as GetRankRequest;
      if (request.amenityType === 2) {
        request.commonFlg = this.inputParams.commonFlg;
      }
      await PointRankGet.get(request);
      if (PointRankGet.isSuccess) {
        item = { ...item, ...PointRankGet.getResults };
        if (item.fuyo) {
          this.rankFuyo = item.fuyo;
        }
        if (item.ticket) {
          this.rankTicket = {
            ...item.ticket,
            imageEditFlg:
              item.ticket.imageEditFlg == null ? 0 : item.ticket.imageEditFlg
          };
          //NEW_DEV-1549 cyber start
          this.initRankTicketImageUrl = item.ticket.image;
          //NEW_DEV-1549 cyber end
        }
      } else {
        await Flash.setErrorNow({
          message: PointRankGet.getMessage,
          showReloadButton: false
        } as ErrorAlert);
        return;
      }
    } else if (item.isFuyoChanged) {
      // 付与設定再取得
      const request = {
        shopId: Number(this.shopId),
        pointId: this.pointId,
        rank: item.rank,
        amenityType: amenityType
      } as GetRankRequest;
      if (request.amenityType === 2) {
        request.commonFlg = this.inputParams.commonFlg;
      }
      await PointRankGet.get(request);
      if (PointRankGet.isSuccess) {
        this.rankFuyo = PointRankGet.getResults.fuyo;
      } else {
        await Flash.setErrorNow({
          message: PointRankGet.getMessage,
          showReloadButton: false
        } as ErrorAlert);
        return;
      }

      item.isFuyoChanged = false;
    } else {
      this.rankFuyo = [];
      if (item.fuyo) {
        for (const fuyo of item.fuyo) {
          this.rankFuyo.push({ ...fuyo });
        }
      }
    }

    // モーダルの data に値を反映
    this.rankModalTitle = this.getRankName(item.rank);
    this.initRankImageUrl = item.rankIcon;
    //NEW_DEV-1549 cyber start
    this.rankIconEditFlg = item.rankIconEditFlg;
    this.rankIcon = item.rankIcon;
    this.rankIconId = item.rankIconId;
    //NEW_DEV-1549 cyber end
    this.rankName = item.rankName;
    this.targetValue = item.targetValue;

    //NEW_DEV-1551 cyber start
    this.$set(this.rankTicket, "attention", item.ticket.attention);
    this.$set(this.rankTicket, "description", item.ticket.description);
    this.$set(this.rankTicket, "image", item.ticket.image);
    this.$set(this.rankTicket, "imageId", item.ticket.imageId);
    this.$set(this.rankTicket, "title", item.ticket.title);
    this.$set(this, "initRankTicketImageUrl", item.ticket.image);
    //NEW_DEV-1551 cyber end
  }

  async changeRankFuyoCommonFlg() {
    if (this.inputParams.amenityType != 2) {
      return;
    }
    // 付与設定変更時は各ランクの編集フラグを戻す
    for (const rank of this.inputRankParams) {
      rank.isFuyoChanged = true;
    }
  }

  doSaveOnModal(response: SpecialDayModalResponse) {
    if (this.selectingSpecial) {
      this.$set(this.selectingSpecial, "isEdited", true);
      if (response.dayType) {
        this.$set(this.selectingSpecial, "dayType", response.dayType);
      }
      if (response.specialPointType) {
        this.$set(
          this.selectingSpecial,
          "specialPointType",
          response.specialPointType
        );
      }
      this.$set(
        this.selectingSpecial,
        "specialPointCount",
        response.specialPointCount
      );
      this.$set(
        this.selectingSpecial,
        "specialPointRate",
        response.specialPointRate
      );
      this.$set(this.selectingSpecial, "specialDays", response.specialDays);
      this.$set(this.selectingSpecial, "weekStart", response.weekStart);
      this.$set(this.selectingSpecial, "weekEnd", response.weekEnd);
    }
  }

  doSaveOnRankModal() {
    this.showRankModal = false;
    if (this.selectingRank) {
      if (this.rankName) {
        this.$set(this.selectingRank, "rankName", this.rankName);
      }
      this.$set(this.selectingRank, "rankIcon", this.rankIcon);
      this.$set(this.selectingRank, "rankIconEditFlg", this.rankIconEditFlg);
      this.$set(this.selectingRank, "rankIconId", this.rankIconId);
      this.$set(this.selectingRank, "targetValue", this.targetValue);
      this.$set(this.selectingRank, "isEdited", true);
      if (!this.selectingRank.fuyo) {
        this.$set(this.selectingRank, "fuyo", []);
      }
      for (let i = 0; i < this.rankFuyo.length; i++) {
        if (this.selectingRank.fuyo![i]) {
          this.$set(
            this.selectingRank.fuyo![i],
            "pointType",
            this.rankFuyo[i].pointType
          );
          this.$set(
            this.selectingRank.fuyo![i],
            "percent",
            this.rankFuyo[i].percent
          );
          this.$set(
            this.selectingRank.fuyo![i],
            "amountPerPoint",
            this.rankFuyo[i].amountPerPoint
          );
          this.$set(
            this.selectingRank.fuyo![i],
            "pointsPerAmount",
            this.rankFuyo[i].pointsPerAmount
          );
        } else {
          this.selectingRank.fuyo![i] = {
            shopId: this.rankFuyo[i].shopId,
            shopName: this.rankFuyo[i].shopName,
            pointType: this.rankFuyo[i].pointType,
            percent: this.rankFuyo[i].percent,
            amountPerPoint: this.rankFuyo[i].amountPerPoint,
            pointsPerAmount: this.rankFuyo[i].pointsPerAmount
          } as GetFuyo;
        }
      }

      if (!this.selectingRank.ticket) {
        this.$set(this.selectingRank, "ticket", {});
      }
      this.$set(this.selectingRank.ticket!, "title", this.rankTicket.title);
      this.$set(
        this.selectingRank.ticket!,
        "imageEditFlg",
        this.rankTicket.imageEditFlg
      );
      this.$set(this.selectingRank.ticket!, "image", this.rankTicket.image);
      this.$set(this.selectingRank.ticket!, "imageId", this.rankTicket.imageId);
      this.$set(
        this.selectingRank.ticket!,
        "description",
        this.rankTicket.description
      );
      this.$set(
        this.selectingRank.ticket!,
        "attention",
        this.rankTicket.attention
      );
    }
  }

  doSaveOnAddPointModal() {
    this.showAddPointModal = false;
    if (this.selectingFuyo) {
      this.$set(this.selectingFuyo, "pointType", this.addPointType);
      this.$set(this.selectingFuyo, "percent", Number(this.addPointPercent));
      this.$set(this.selectingFuyo, "amountPerPoint", Number(this.amountPerPoint));
      this.$set(this.selectingFuyo, "pointsPerAmount", Number(this.pointsPerAmount));
      this.$set(this.selectingFuyo, "isEdited", true);
    }
  }

  private syncInputParams() {
    const detail = PointSettingDetail.getDetail;
    const detailSpecial = detail.special;
    const detailRank = detail.rank;
    const detailFuyo = detail.fuyo;
    //NEW_DEV-1545 cyber start
    this.previewRank = detail.rank;
    //NEW_DEV-1545 cyber end
    this.pointId = detail.id;
    delete detail.id;
    delete detail.special;
    delete detail.rank;
    delete detail.fuyo;
    this.inputParams = {
      shopId: Number(this.shopId),
      ...detail,
      introduceImageEditFlg: 0,
      introduceImageId: null,
      completeTicket: null,
      special: null,
      rank: null,
      fuyo: null,
      isPreview: 0
    };
    if (detail.completeTicket) {
      this.inputParams.completeTicket = {
        ...detail.completeTicket,
        imageEditFlg: 0,
        imageId: null
      };
    }
    this.initImageUrl = this.inputParams.introduceImage;
    // スペシャルデー設定のsync
    if (detailSpecial) {
      for (let special of detailSpecial) {
        this.inputSpecialParams.push({
          ...special,
          dayType: 1,
          specialDays: null,
          weekStart: null,
          weekEnd: null,
          isEdited: false
        } as SpecialDay);
      }
    }
    // ポイント付与設定のsync
    if (detailFuyo) {
      if (detailFuyo.common) {
        // 全店共通設定
        for (const fuyo of detailFuyo.common) {
          // NEW_DEV-1667 cyber start
          fuyo.shopName = "全店共通設定"
          // NEW_DEV-1667 cyber end
          this.inputCommonFuyoParams.push({
            ...fuyo,
            isEdited: false,
            writeFlg: 1
          } as Fuyo);
        }
      }
      if (detailFuyo.branch) {
        // 支店別設定
        for (const fuyo of detailFuyo.branch) {
          this.inputBranchFuyoParams.push({
            ...fuyo,
            isEdited: false,
            writeFlg: 1
          } as Fuyo);
        }
      }
    }
    // ランク設定のsync
    if (detailRank) {
      for (const rank of detailRank) {
        this.inputRankParams.push({
          ...rank,
          rankIconEditFlg: 0,
          rankIconId: null,
          isEdited: false
        } as Rank);
      }
    }
    // コンプリートチケットのsync
    if (this.inputParams.completeTicket) {
      this.inputCompTicketParams = { ...this.inputParams.completeTicket };
      this.initTicketImageUrl = this.inputCompTicketParams.image;
    }
  }

  private createRegisterRequest() {
    if (this.inputParams.isUse !== 1) {
      // ポイントカード設定を利用しない場合
      return { shopId: this.inputParams.shopId, isUse: 0 } as RegisterRequest;
    }
    if (this.inputParams.orgPointFlg === 1) {
      // 店舗カードの場合
      return {
        shopId: this.inputParams.shopId,
        isUse: 1,
        orgPointUrlFlg: this.inputParams.orgPointUrlFlg,
        orgPointUrl: this.inputParams.orgPointUrl,
        orgPointUrlName: this.inputParams.orgPointUrlName,
        orgPointDesc: this.inputParams.orgPointDesc
      } as RegisterRequest;
    }
    if (this.inputParams.pointType === 1 && this.inputParams.rankUse === 1) {
      this.inputParams.amenityType = 2;
    }
    const request = { ...this.inputParams };
    delete request.orgPointUrlFlg;
    delete request.orgPointUrl;
    delete request.orgPointUrlName;
    delete request.orgPointDesc;
    // スペシャルデー設定
    if (request.specialBulkFlg === 0) {
      // 全店共通設定
      if (this.mainSpecialItem.isEdited) {
        request.special = [
          {
            shopId: this.mainSpecialItem.shopId,
            pointType: this.mainSpecialItem.specialPointType,
            pointCount: this.mainSpecialItem.specialPointCount,
            pointRate: this.mainSpecialItem.specialPointRate,
            specialDayType: this.mainSpecialItem.dayType,
            specialDays: _.cloneDeep(this.mainSpecialItem.specialDays),
            specialWeekStart: this.mainSpecialItem.weekStart,
            specialWeekEnd: this.mainSpecialItem.weekEnd
          } as RegisterSpecial
        ];
      } else {
        request.special = [];
      }
    } else {
      // 店舗別設定
      request.special = [];

      for (const special of this.branchSpecialItems) {
        if (special.isEdited) {
          request.special.push({
            shopId: special.shopId,
            pointType: special.specialPointType,
            pointCount: special.specialPointCount,
            pointRate: special.specialPointRate,
            specialDayType: special.dayType,
            specialDays: _.cloneDeep(special.specialDays),
            specialWeekStart: special.weekStart,
            specialWeekEnd: special.weekEnd
          } as RegisterSpecial);
        }
      }
    }
    // お友達紹介特典設定
    if (request.introduceFlg === 0) {
      request.introduceImageEditFlg = 0;
      delete request.introduceImage;
      delete request.introduceDescription;
      delete request.friendDl;
      delete request.friendIntroduceCount;
      delete request.friendUse;
      delete request.friendIntroduceCountBoth;
      delete request.userDl;
      delete request.userIntroduceCount;
      delete request.userUse;
      delete request.userIntroduceCountBoth;
    }
    if (
      request.introduceImageEditFlg == 0 ||
      request.introduceImageId != null
    ) {
      delete request.introduceImage;
    }
    // ポイント減算方式
    if (request.pointType === 1) {
      delete request.grantPoint;
      delete request.completeTicket;
      delete request.completeExpirationDate;
      if (request.rankUse === 1) {
        // 利用する
        delete request.fuyo;
        request.rank = [];
        for (const rank of this.inputRankParams) {
          //NEW_DEV-1545 cyber start
          //if (rank.isEdited) {
            const reqRank = {
              rank: rank.rank,
              rankName: rank.rankName,
              targetValue: rank.targetValue,
              rankIconEditFlg: rank.rankIconEditFlg,
              rankIcon: rank.rankIcon,
              rankIconId: rank.rankIconId,
              fuyo: _.cloneDeep(rank.fuyo)
            } as RegisterRank;
            if (reqRank.rankIconEditFlg == 0 || reqRank.rankIconId != null) {
              delete reqRank.rankIcon;
            }
            request.rank.push(reqRank);
          //}
          //NEW_DEV-1545 cyber end
        }
      } else {
        // 利用しない
        delete request.rank;
        delete request.rankDescription;
        this.addFuyoRequestPrams(request);
      }
    }
    // コンプリートチケット方式
    if (request.pointType === 2) {
      if (request.rankUse === 1) {
        // 利用する
        request.rank = [];
        if (request.amenityType === 1) {
          // チケット
          for (const rank of this.inputRankParams) {
            //NEW_DEV-1545 cyber start
            //if (rank.isEdited) {
              const reqRank = {
                rank: rank.rank,
                rankName: rank.rankName,
                targetValue: rank.targetValue,
                rankIconEditFlg: rank.rankIconEditFlg,
                rankIcon: rank.rankIcon,
                rankIconId: rank.rankIconId,
                ticket: _.cloneDeep(rank.ticket)
              } as RegisterRank;
              if (reqRank.rankIconEditFlg == 0 || reqRank.rankIconId != null) {
                //delete reqRank.rankIcon;
              }
              request.rank.push(reqRank);
            //}
            //NEW_DEV-1545 cyber end
          }
          this.addFuyoRequestPrams(request);
          //NEW_DEV-1545 cyber start
          request.completeTicket = { ...this.inputCompTicketParams };
          //NEW_DEV-1545 cyber end
        }
        if (request.amenityType === 2) {
          // ポイント
          for (const rank of this.inputRankParams) {
            //NEW_DEV-1545 cyber start
            //if (rank.isEdited) {
              const reqRank = {
                rank: rank.rank,
                rankName: rank.rankName,
                targetValue: rank.targetValue,
                rankIcon: rank.rankIcon,
                //NEW_DEV-1724 cyber start
                rankIconId: rank.rankIconId,
                //NEW_DEV-1724 cyber end
                rankIconEditFlg: rank.rankIconEditFlg,
                fuyo: _.cloneDeep(rank.fuyo)
              } as RegisterRank;
              if (reqRank.rankIconEditFlg == 0 || reqRank.rankIconId != null) {
                delete reqRank.rankIcon;
              }
              request.rank.push(reqRank);
            //}
            //NEW_DEV-1545 cyber end
          }
          //this.addFuyoRequestPrams(request);

          request.completeTicket = { ...this.inputCompTicketParams };
        }
      } else {
        // 利用しない
        delete request.rank;
        delete request.rankDescription;
        //delete request.commonFlg;

        request.completeTicket = { ...this.inputCompTicketParams };
        if (
          request.completeTicket &&
          (request.completeTicket.imageEditFlg == 0 ||
            request.completeTicket.imageId != null)
        ) {
          delete request.completeTicket.image;
        }
        this.addFuyoRequestPrams(request);
      }
    }
    return request;
  }

  private addFuyoRequestPrams(request: RegisterRequest) {
    request.fuyo = [];
    const fuyoParams =
      this.inputParams.commonFlg === 1
        ? this.inputBranchFuyoParams
        : this.inputCommonFuyoParams;

    for (const fuyo of fuyoParams) {
      //if (fuyo.isEdited) {
        request.fuyo.push({
          shopId: fuyo.shopId,
          pointType: fuyo.pointType,
          percent: fuyo.percent,
          amountPerPoint: fuyo.amountPerPoint,
          pointsPerAmount: fuyo.pointsPerAmount
        } as RegisterFuyo);
      //}
    }
  }

  private async fetchSpecialDay(item: SpecialDay) {
    await PointSpecialGet.get({
      shopId: item.shopId,
      pointId: this.pointId,
      bulkFlg: item.bulkFlg
    } as GetSpecialRequest);
    if (!PointSpecialGet.isSuccess) {
      await Flash.setErrorNow({
        message: PointSpecialGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }

  private async initPointFuyoModal(item: Fuyo, isCommon: number) {
    this.showAddPointModal = true;
    this.selectingFuyo = item;
    if (!item.isEdited) {
      // APIで値を取得していなければ、取得する
      await PointFuyoGet.get({
        pointId: this.pointId,
        shopId: item.shopId,
        commonFlg: isCommon
      } as GetFuyoRequest);
      if (PointFuyoGet.isSuccess) {
        item = { ...item, ...PointFuyoGet.getResults };
      } else {
        await Flash.setErrorNow({
          message: PointFuyoGet.getMessage,
          showReloadButton: false
        } as ErrorAlert);
        return;
      }
    }
    // モーダルの data に値を反映
    this.addPointType = item.pointType;
    this.addPointPercent = item.percent;
    this.amountPerPoint = item.amountPerPoint;
    this.pointsPerAmount = item.pointsPerAmount;
  }

  private showSaveLabel() {
    return (
      this.inputParams.isUse === 0 ||
      this.inputParams.orgPointFlg === 1 ||
      this.nextLabel === NextLabelEnum.SAVE
    );
  }
}
