import service from "@/api/service";
import { DetailRequest, RegisterRequest } from "@/api/point-setting/request";
import { DetailResponse, RegisterResponse } from "@/api/point-setting/response";

/**
 * ポイントカード設定情報取得APIをコールします。
 *
 * @param detailRequest ポイントカード詳細のリクエストボディ
 * @return DetailResponse
 */
export async function detail(detailRequest: DetailRequest) {
  const response = await service.post("/get-point-setting", detailRequest);
  return response.data as DetailResponse;
}

/**
 * ポイントカード設定登録更新APIをコールします。
 *
 * @param registerRequest ポイントカード登録のリクエストボディ
 * @return CommonResponse
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post("/regist-point-setting", registerRequest);
  return response.data as RegisterResponse;
}
