






































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import UIFormRow from "@/components/UIFormRow.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import UlFileRegister from "@/components/UlFileRegister.vue";
import {
  UIModalSpecialDayModalType,
  UlFileRegisiterTabType
} from "@/utils/enums";
import { SpecialDay, SpecialDayModalResponse } from "@/utils/interfaces";

@Component({
  components: {
    UIFormRow,
    UIDatePicker,
    UlFileRegister
  }
})
export default class UlModalSpecialDay extends Vue {
  @Prop() value!: boolean;
  @Prop() modalType!: UIModalSpecialDayModalType;
  @Prop() initParams?: SpecialDayModalResponse;
  @Prop() imageShopId?: string;
  @Prop() saveCallback!: (response: SpecialDayModalResponse) => void;

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  localParams = {
    /** 画像 */
    imagePath: null,
    /** スペシャルデースタンプ数 */
    stampCount: null,
    /** 付与設定 1:ポイント固定、2:倍率 */
    specialPointType: null,
    /** 付与ポイント数 */
    specialPointCount: null,
    /** ポイント倍率 */
    specialPointRate: null,
    /** 期間タイプ 0:未設定、1:日にち指定、2:週ごと、3:月ごと、4:期間指定 */
    dayType: 1,
    /** 配信期間設定配列 */
    specialDays: {
      /** 配信間隔 */
      unit: 1,
      /** 月初チェックフラグ */
      monthStart: null,
      /** 月末チェックフラグ */
      monthEnd: null,
      /** 指定日配列 */
      specific: null,
      /** 期間週数 */
      interval: null,
      /** 曜日の配列（ON/OFF） */
      week: null
    },
    /** 期間開始日または指定日 */
    weekStart: null,
    /** 配信終了日 */
    weekEnd: null
  } as SpecialDayModalResponse;

  allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  tabType = UlFileRegisiterTabType.Photo;

  weekStartDefaultDate = null as string | null;
  weekEndDefaultDate = null as string | null;

  validationErrors = [];

  activeSpecialDayMonthlyTab = 0;

  get shopName() {
    return this.$route.query.shopName;
  }

  get activeSpecialDayTab() {
    switch (this.localParams.dayType) {
      case 1:
        return 0;
      case 2:
        return 1;
      case 3:
        return 2;
      case 4:
        return 3;
      default:
        return 0;
    }
  }

  set activeSpecialDayTab(dayType) {
    switch (dayType) {
      case 0:
        this.localParams.dayType = 1;
        break;
      case 1:
        this.localParams.dayType = 2;
        break;
      case 2:
        this.localParams.dayType = 3;
        break;
      case 3:
        this.localParams.dayType = 4;
        break;
      default:
        this.localParams.dayType = 1;
    }
  }

  get activePointTypeTab() {
    switch (this.localParams.specialPointType) {
      case 1:
        return 0;
      case 2:
        return 1;
      default:
        return 0;
    }
  }

  set activePointTypeTab(pointType) {
    switch (pointType) {
      case 0:
        this.localParams.specialPointType = 1;
        break;
      case 1:
        this.localParams.specialPointType = 2;
        break;
      default:
        this.localParams.specialPointType = 1;
    }
  }

  get isFirstWeek() {
    return this.firstWeekDays.length >= 7;
  }

  set isFirstWeek(val) {
    if (val) {
      this.firstWeekDays = [0, 1, 2, 3, 4, 5, 6];
    } else {
      this.firstWeekDays = [];
    }
  }

  get isSecondWeek() {
    return this.secondWeekDays.length >= 7;
  }

  set isSecondWeek(val) {
    if (val) {
      this.secondWeekDays = [0, 1, 2, 3, 4, 5, 6];
    } else {
      this.secondWeekDays = [];
    }
  }

  get isThirdWeek() {
    return this.thirdWeekDays.length >= 7;
  }

  set isThirdWeek(val) {
    if (val) {
      this.thirdWeekDays = [0, 1, 2, 3, 4, 5, 6];
    } else {
      this.thirdWeekDays = [];
    }
  }

  get isFourthWeek() {
    return this.fourthWeekDays.length >= 7;
  }

  set isFourthWeek(val) {
    if (val) {
      this.fourthWeekDays = [0, 1, 2, 3, 4, 5, 6];
    } else {
      this.fourthWeekDays = [];
    }
  }

  get isLastWeek() {
    return this.lastWeekDays.length >= 7;
  }

  set isLastWeek(val) {
    if (val) {
      this.lastWeekDays = [0, 1, 2, 3, 4, 5, 6];
    } else {
      this.lastWeekDays = [];
    }
  }

  // モーダルdata
  weekDays = null as number[] | null;
  initImageUrl = null as string | null;
  isSelectDate = false;
  firstWeekDays = [] as number[];
  secondWeekDays = [] as number[];
  thirdWeekDays = [] as number[];
  fourthWeekDays = [] as number[];
  lastWeekDays = [] as number[];

  @Emit()
  public input(value: boolean) {}

  @Watch("initParams")
  watchInitParams(
    newParams: SpecialDayModalResponse | null,
    oldParams: SpecialDayModalResponse | null
  ) {
    if (newParams) {
      this.initLocalParams(newParams);
    }
  }

  dayItems = [...Array(31).keys()].map(n => {
    const val = n + 1;
    return { text: val, value: val };
  });

  weekDayItems = [
    { text: "月曜日", value: 0 },
    { text: "火曜日", value: 1 },
    { text: "水曜日", value: 2 },
    { text: "木曜日", value: 3 },
    { text: "金曜日", value: 4 },
    { text: "土曜日", value: 5 },
    { text: "日曜日", value: 6 }
  ];

  get showImageUploadFormOnModal() {
    return this.modalType === UIModalSpecialDayModalType.Single;
  }

  get showStampFormOnModal() {
    switch (this.modalType) {
      case UIModalSpecialDayModalType.All:
      case UIModalSpecialDayModalType.Single:
        return true;
      case UIModalSpecialDayModalType.Point:
        return false;
      default:
        return false;
    }
  }

  get showPointFormOnModal() {
    switch (this.modalType) {
      case UIModalSpecialDayModalType.All:
      case UIModalSpecialDayModalType.Single:
        return false;
      case UIModalSpecialDayModalType.Point:
        return true;
      default:
        return false;
    }
  }

  setModalFile(data: string, id: number | null) {
    if (this.localParams) {
      this.localParams.imageEditFlg = 1;
      this.localParams.imagePath = data;
      this.localParams.imageId = id;
    }
  }

  unsetModalFile() {
    if (this.localParams) {
      this.localParams.imageEditFlg = 1;
      this.localParams.imagePath = null;
      this.localParams.imageId = null;
    }
  }

  changeDateCallback(date: string) {
    this.localParams.weekStart = date;
  }

  changeFromDateCallback(date: string) {
    this.localParams.weekStart = date;
  }

  changeToDateCallback(date: string) {
    this.localParams.weekEnd = date;
  }

  onSave() {
    if (this.showPointFormOnModal && !this.pointValidation()) {
      return false;
    }
    if (this.showStampFormOnModal && !this.stampValidation()) {
      return false;
    }
    this.localValue = false;
    switch (this.localParams.dayType) {
      case 2:
        // 週ごとに設定
        if (this.localParams.specialDays) {
          if (this.weekDays) {
            const week = this.convertWeedDataToParams(this.weekDays);
            this.localParams.specialDays.week = [week];
          }
        }
        break;
      case 3:
        // 月ごとに設定
        if (this.localParams.specialDays) {
          this.localParams.specialDays.unit =
            this.activeSpecialDayMonthlyTab === 0 ? 1 : 2;
          if (this.localParams.specialDays.unit === 1) {
            // 日単位で設定
            if (!this.isSelectDate) {
              this.localParams.specialDays.specific = null;
            }
          } else {
            // 週単位で設定
            this.localParams.specialDays.week = [];
            let week = this.convertWeedDataToParams(this.firstWeekDays);
            this.localParams.specialDays.week.push(week);
            week = this.convertWeedDataToParams(this.secondWeekDays);
            this.localParams.specialDays.week.push(week);
            week = this.convertWeedDataToParams(this.thirdWeekDays);
            this.localParams.specialDays.week.push(week);
            week = this.convertWeedDataToParams(this.fourthWeekDays);
            this.localParams.specialDays.week.push(week);
            week = this.convertWeedDataToParams(this.lastWeekDays);
            this.localParams.specialDays.week.push(week);
          }
        }
        break;
    }
    this.saveCallback(this.localParams);
  }

  private stampValidation(): boolean {
    this.validationErrors = [];
    var numReg = /^\+?([1-9][0-9]*|0)$/
    var numRe = new RegExp(numReg);
    if (this.localParams.stampCount && !numRe.test(this.localParams.stampCount)){
      this.validationErrors.push('スペシャルデー設定のスタンプ数には整数を指定してください。');
    }
    

    // 日にちで設定
    if (this.activeSpecialDayTab == 0) {
      if (!this.localParams.stampCount && this.localParams.weekStart) {
        this.validationErrors.push('付与スタンプ数を設定してください');
      }   
      if (!this.localParams.weekStart && this.localParams.stampCount) {
        this.validationErrors.push('日にちの設定は必須です。');
      }
    // 週ごとで設定
    } else if (this.activeSpecialDayTab == 1) {
      if (!this.localParams.stampCount && (this.localParams.specialDays.interval || this.weekDays)) {
        this.validationErrors.push('付与スタンプ数を設定してください');
      }   
      if (!this.localParams.specialDays.interval && (this.localParams.stampCount || this.weekDays)) {
        this.validationErrors.push('週ごとで設定の配信間隔は必須です。');
      }
      var intervalReg = /^\+?([1-9][0-9]*|0)$/
      var intervalRe = new RegExp(intervalReg);
      if (this.localParams.specialDays.interval && !intervalRe.test(this.localParams.specialDays.interval)) {
        this.validationErrors.push('週ごとで設定の配信間隔は整数を指定してください。');
      }
      if (!this.weekDays && (this.localParams.specialDays.interval || this.localParams.stampCount)) {
        this.validationErrors.push('週ごとで設定の曜日選択は必須です。');
      }
    // 月ごとで設定
    } else if (this.activeSpecialDayTab == 2) {
      // 日単位で設定
      if (this.activeSpecialDayMonthlyTab == 0) {
        if (!this.localParams.stampCount && (this.localParams.specialDays.monthStart || this.localParams.specialDays.monthEnd || this.isSelectDate)) {
          this.validationErrors.push('付与スタンプ数を設定してください');
        }
        if ((!this.localParams.specialDays.monthStart && !this.localParams.specialDays.monthEnd && !this.isSelectDate) && this.localParams.stampCount) {
          this.validationErrors.push('日単位で設定の選択は必須です。');
        }
        if (this.isSelectDate && (!this.localParams.specialDays.specific || !this.localParams.specialDays.specific.length)) {
          this.validationErrors.push('日付を指定する場合日にちの選択は必須です。');
        }
      // 週単位で設定
      } else if (this.activeSpecialDayMonthlyTab == 1) {
        if (!this.localParams.stampCount && (this.firstWeekDays.length || this.secondWeekDays.length || this.thirdWeekDays.length || this.fourthWeekDays.length || this.lastWeekDays.length)) {
          this.validationErrors.push('付与スタンプ数を設定してください');
        }
        if ((!this.firstWeekDays.length && !this.secondWeekDays.length && !this.thirdWeekDays.length && !this.fourthWeekDays.length && !this.lastWeekDays.length) && this.localParams.stampCount) {
          this.validationErrors.push('週単位で設定する場合週の選択または曜日の選択は必須です。');
        }
      }
    // 期間で設定
    } else if (this.activeSpecialDayTab == 3) {
      if (!this.localParams.stampCount && (this.localParams.weekStart || this.localParams.weekEnd)) {
        this.validationErrors.push('付与スタンプ数を設定してください');
      }
      if ((this.localParams.stampCount && (!this.localParams.weekStart || !this.localParams.weekEnd)) || (!this.localParams.weekStart && this.localParams.weekEnd) || (this.localParams.weekStart && !this.localParams.weekEnd)) {
        this.validationErrors.push('期間で設定する場合開始日、終了日の日にちを設定してください。');
      }
      if ((this.localParams.weekStart && this.localParams.weekEnd) && new Date(this.localParams.weekStart.replace(/-/g, "/")) >= new Date(this.localParams.weekEnd.replace(/-/g, "/"))) {
        this.validationErrors.push('期間で設定する場合終了日には開始日以降の日付を指定してください。');
      }
    }
    if (this.validationErrors.length > 0) {
      this.$refs.modal.$children[0].$el.scrollTop = 0;
      return false;
    }

    return true;
  }

  private pointValidation(): boolean {
    this.validationErrors = [];
    //NEW_DEV-1024 cyber start
    var numReg = /^\d{1,11}$/
    var numRe = new RegExp(numReg);
    if ((this.localParams.specialPointType == 1 && !this.localParams.specialPointCount) ||
    (this.localParams.specialPointType == 2 && !this.localParams.specialPointRate)) {
      this.validationErrors.push('付与ポイント設定を設定してください');
    }

    if (this.localParams.specialPointType == 1 && this.localParams.specialPointCount && !numRe.test(this.localParams.specialPointCount)){
      this.validationErrors.push('スペシャルデー設定のポイント数には1〜11桁の数値を指定してください。');
    }
    if (this.localParams.specialPointType == 2 && this.localParams.specialPointRate && !numRe.test(this.localParams.specialPointRate)) {
      this.validationErrors.push('スペシャルデー設定のポイント割合には1〜11桁の数値を指定してください。');
    }

    // 日にちで設定
    if (this.activeSpecialDayTab == 0 && !this.localParams.weekStart) {
      this.validationErrors.push('日にちの設定は必須です。');
    // 週ごとで設定
    } else if (this.activeSpecialDayTab == 1) {
      if (!this.localParams.specialDays.interval) {
        this.validationErrors.push('週ごとで設定の配信間隔は必須です。');
      }
      var intervalReg = /^\+?([1-9][0-9]*|0)$/
      var intervalRe = new RegExp(intervalReg);
      if (this.localParams.specialDays.interval && !intervalRe.test(this.localParams.specialDays.interval)) {
        this.validationErrors.push('週ごとで設定の配信間隔は整数を指定してください。');
      }
      //NEW_DEV-1024 cyber end
      //NEW_DEV-1661 cyber start
      if (!this.weekDays || !this.weekDays.length) {
      //NEW_DEV-1661 cyber end
        this.validationErrors.push('週ごとで設定の曜日選択は必須です。');
      }
    // 月ごとで設定
    } else if (this.activeSpecialDayTab == 2) {
      // 日単位で設定
      if (this.activeSpecialDayMonthlyTab == 0) {
          if (!this.localParams.specialDays.monthStart && !this.localParams.specialDays.monthEnd && !this.isSelectDate) {
            // NEW_DEV-2210_add cyber start
            if (!this.localParams.specialDays.specific || !this.localParams.specialDays.specific.length) {
              this.validationErrors.push('日単位で設定は必須です。');
            } else {
              this.validationErrors.push('日単位で設定の選択は必須です。');
            }
            // NEW_DEV-2210_add cyber end
          }

          if (this.isSelectDate && (!this.localParams.specialDays.specific || !this.localParams.specialDays.specific.length)) {
            this.validationErrors.push('日付を指定する場合日にちの選択は必須です。');
          }
      // 週単位で設定
      } else if (this.activeSpecialDayMonthlyTab == 1) {
        if (!this.firstWeekDays.length && !this.secondWeekDays.length && !this.thirdWeekDays.length && !this.fourthWeekDays.length && !this.lastWeekDays.length) {
          this.validationErrors.push('週単位で設定する場合週の選択または曜日の選択は必須です。');
        }
      }
    // 期間で設定
    } else if (this.activeSpecialDayTab == 3 && (!this.localParams.weekStart || !this.localParams.weekEnd)) {
      this.validationErrors.push('期間で設定する場合開始日、終了日の日にちを設定してください。');
    // NEW_DEV-1663 cyber start
     // NEW_DEV-1879 cyber start
    } else if (this.activeSpecialDayTab == 3 &&(this.localParams.weekStart && this.localParams.weekEnd) && new Date(this.localParams.weekStart.replace(/-/g, "/")) >= new Date(this.localParams.weekEnd.replace(/-/g, "/")) ){
     // NEW_DEV-1879 cyber end
    this.validationErrors.push('期間で設定する場合終了日には開始日以降の日付を指定してください。');
    }
    // NEW_DEV-1663 cyber end
    if (this.validationErrors.length > 0) {
      this.$refs.modal.$children[0].$el.scrollTop = 0;
      return false;
    }

    return true;
  }

  private get localValue(): boolean {
    return this.value;
  }

  private set localValue(value: boolean) {
    this.input(value);
  }

  private initLocalParams(params: SpecialDayModalResponse) {
    // 1桁の数字を0埋めで2桁にする
    let toDoubleDigits = function(num: number) {
      let strNum = String(num);
      if (strNum.length === 1) {
        strNum = "0" + strNum;
      }
      return strNum;
    };

    this.localParams = { ...params };
    if (this.localParams.weekStart === null) {
      let currentDate = new Date();
      let year = toDoubleDigits(currentDate.getFullYear());
      let month = toDoubleDigits(currentDate.getMonth() + 1);
      let date = toDoubleDigits(currentDate.getDate());
      this.localParams.weekStart = year + "-" + month + "-" + date;
    }
    if (!this.localParams.specialDays) {
      this.localParams.specialDays = {} as SpecialDay;
    }
    if (!this.localParams.specialDays.unit) {
      this.localParams.specialDays.unit = 1;
    }
    switch (this.localParams.specialDays.unit) {
      case 1:
        this.activeSpecialDayMonthlyTab = 0;
        break;
      case 2:
        this.activeSpecialDayMonthlyTab = 1;
        break;
      default:
        this.activeSpecialDayMonthlyTab = 0;
    }
    if (!this.localParams.specialDays.specific) {
      this.localParams.specialDays.specific = [];
    }
    this.weekStartDefaultDate = this.localParams.weekStart;
    this.weekEndDefaultDate = this.localParams.weekEnd;
    this.initImageUrl = this.localParams.imagePath;
    this.isSelectDate =
      this.localParams.specialDays.specific &&
      this.localParams.specialDays.specific.length > 0;
    this.weekDays = null;
    if (this.localParams.specialDays && this.localParams.specialDays.week) {
      // 週ごとなので1週分しかない
      if (this.localParams.dayType === 2) {
        this.weekDays = this.convertWeekParamsToData(
          this.localParams.specialDays.week[0]
        );
      }
      // 月ごとの週単位の場合
      if (
        this.localParams.dayType === 3 &&
        this.localParams.specialDays.unit === 2
      ) {
        let count = 1;
        for (const week of this.localParams.specialDays.week) {
          if (count === 1) {
            // 第1週
            this.firstWeekDays = this.convertWeekParamsToData(week);
          } else if (count === 2) {
            // 第2週
            this.secondWeekDays = this.convertWeekParamsToData(week);
          } else if (count === 3) {
            // 第3週
            this.thirdWeekDays = this.convertWeekParamsToData(week);
          } else if (count === 4) {
            // 第4週
            this.fourthWeekDays = this.convertWeekParamsToData(week);
          } else if (count === 5) {
            // 最終週
            this.lastWeekDays = this.convertWeekParamsToData(week);
          }
          count++;
        }
      }
    }
  }

  private convertWeekParamsToData(week: number[]) {
    let weekDays = [];
    for (let i = 0; i < week.length; i++) {
      if (week[i] === 1) {
        weekDays.push(i);
      }
    }
    return weekDays;
  }

  private convertWeedDataToParams(weekDays: number[]) {
    let week = [0, 0, 0, 0, 0, 0, 0];
    for (let w of weekDays) {
      week[w] = 1;
    }
    return week;
  }
}
