import service from "@/api/service";
import { GetResponse } from "@/api/point-ticket-preview/response";
import { GetRequest } from "@/api/point-ticket-preview/request";

/**
 * ポイント特典チケットプレビューAPIをコールします。
 *
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/point-ticket-preview", getRequest);
  return response.data as GetResponse;
}
